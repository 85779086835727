import { useQuery } from 'react-query';
import { FeatureCollection } from 'geojson';
import { Insights, PathfinderData, PathfinderFiles } from '../utils/interfaces';
import { ReportType } from '../utils/enums';
import { stringify } from 'query-string';
import { getRequestHeaders, vake_api_server_url } from './helpers';
import { useAuth } from '../auth/AuthProvider';
import { StoryConfig } from '../shared/components/storytelling/types/data';
import { SubscriptionState } from '../pages/Report/Build/OrderOverwatchReport';

// Vake-api

export const useMetadataQuery = (params: any, reportType: ReportType, searchParams?: any) => {
    const id = params.id ? params.id : 'demo';
    const { token } = useAuth();

    const reportTypeString = reportType === ReportType.PathfinderReport ? 'pathfinder' : 'overwatch';

    let url = `${vake_api_server_url}/report/${reportTypeString}/${id}/metadata`;

    // TODO: Make this prettier. Check if aoi is in SearchParams
    if (searchParams && Object.values(searchParams).length > 0) {
        url = `${url}?${stringify({ aoi: searchParams.aoi })}`;
    }

    return useQuery<any>(
        ['metadata', id],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
        }
    );
};

const getInsightsPerDate = async (
    params: any,
    date: Date,
    token: string | null | undefined,
    processing_status: boolean,
    searchParams?: any
) => {
    const id = params.id ? params.id : 'demo';
    let url = `${vake_api_server_url}/report/overwatch/${id}/detections/date?`;

    url += new URLSearchParams({
        ...searchParams,
        date: date.toISOString().split('T')[0],
        processing_status: processing_status,
    }).toString();

    const res = await fetch(url, {
        credentials: 'include',
        headers: getRequestHeaders(token),
    });

    if (!res.ok) {
        throw new Error(res.statusText);
    }

    return await res.json();
};

export const useInsightsPerDateQuery = (params: any, date: Date, processing_status: boolean, searchParams?: any) => {
    const { token } = useAuth();
    return useQuery<Insights>({
        queryKey: ['test_per_date', date, processing_status],
        queryFn: () => getInsightsPerDate(params, date, token, processing_status, searchParams),
        enabled: !!date,
        staleTime: Infinity,
        cacheTime: 1000 * 60 * 60,
    });
};

export const useAvailableDatesQuery = (params: any, searchParams?: any) => {
    const id = params.id ? params.id : 'demo';
    const { token } = useAuth();
    const init_data: Date[] = [];
    let url = `${vake_api_server_url}/report/overwatch/${id}/dates`;

    // TODO: Make this prettier. Check if aoi and dates is in SearchParams
    url = searchParams && Object.values(searchParams).length > 0 ? `${url}?${stringify(searchParams)}` : url;

    return useQuery<Date[]>(
        ['available_dates', id],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }
            let data = await res.json();
            let dates: Date[] = data.map((date: string) => new Date(date));
            return dates;
        },
        {
            placeholderData: init_data,
            staleTime: 1000 * 60 * 60, // one hour
        }
    );
};

export const usePathfinderData = (params: any) => {
    const id = params.id;
    const { token } = useAuth();
    const url = `${vake_api_server_url}/report/pathfinder/${id}/data`;
    return useQuery<PathfinderData>(
        ['pathfinder_data'],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
        }
    );
};

export const sendSlackBotReportMessage = async (
    token: string | null | undefined,
    currUrl: string,
    hasImages: boolean | null,
    hasAIS: boolean | null
) => {
    let url = `${vake_api_server_url}/user-feedback/`;
    const response = await fetch(url, {
        credentials: 'include',
        method: 'POST',
        headers: getRequestHeaders(token),
        body: JSON.stringify({ current_url: currUrl, has_images: hasImages, has_ais: hasAIS }),
    });
    if (!response.ok) {
        throw new Error('slack bot troubles');
    }
    return response.ok;
};

export const updateSlackBotMessage = async (
    token: string | null | undefined,
    currUrl: string,
    hasImages: boolean | null,
    hasAIS: boolean | null
) => {
    let url = `${vake_api_server_url}/user-feedback/update_message`;
    const response = await fetch(url, {
        credentials: 'include',
        method: 'POST',
        headers: getRequestHeaders(token),
        body: JSON.stringify({ current_url: currUrl, has_images: hasImages, has_ais: hasAIS }),
    });
    if (!response.ok) {
        throw new Error('slack bot troubles');
    }
    return response.ok;
};

export const usePathfinderFilesExists = (params: any) => {
    const id = params.id;
    const { token } = useAuth();
    const url = `${vake_api_server_url}/report/pathfinder/${id}/check_files_exists`;
    return useQuery<PathfinderFiles>(
        ['pathfinder_files_exists'],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
        }
    );
};

export const usePathfinderAisData = (params: any) => {
    const id = params.id;
    const { token } = useAuth();
    const url = `${vake_api_server_url}/report/pathfinder/${id}/ais_data`;
    return useQuery<FeatureCollection>(
        ['pathfinder_ais_data'],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
        }
    );
};

export const useStoryData = (params: any) => {
    const id = params.id;
    const { token } = useAuth();
    const url = `${vake_api_server_url}/report/story/${id}/data`;
    return useQuery<StoryConfig>(
        ['story_data'],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
        }
    );
};

export const useCloudsPerDateQuery = (params: any, date: Date, searchParams: any) => {
    const id = params.id ? params.id : 'demo';
    const { token } = useAuth();
    let url = `${vake_api_server_url}/report/overwatch/${id}/clouds`;
    // TODO: Make this prettier. Check if aoi is in SearchParams
    return useQuery<FeatureCollection>(
        ['cloudmasks', date],
        async () => {
            url =
                searchParams && Object.values(searchParams).length > 0
                    ? `${url}?${stringify({ date: date.toISOString().split('T')[0], aoi: searchParams.aoi })}`
                    : `${url}?${stringify({ date: date.toISOString().split('T')[0] })}`;
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }
            let output = await res.json();
            return await output;
        },
        {
            enabled: !!date,
            staleTime: Infinity,
        }
    );
};

export const useCustomDataQuery = (params: any) => {
    const id = params.id ? params.id : 'demo';
    const { token } = useAuth();
    let url = `${vake_api_server_url}/report/overwatch/${id}/custom_data`;
    // TODO: Make this prettier. Check if aoi is in SearchParams
    return useQuery<FeatureCollection>(
        ['customData'],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }
            let output = await res.json();
            return await output;
        },
        {
            staleTime: Infinity,
        }
    );
};

const fetchDataAsFile = async (url: string, accessToken: string | undefined | null, filename?: string) => {
    try {
        fetch(url, {
            method: 'GET',
            headers: getRequestHeaders(accessToken),
        })
            .then((response) => {
                const disposition = response.headers.get('Content-Disposition');
                if (disposition) {
                    const match = disposition.match(/filename=(.+)/);
                    if (match) {
                        filename = match[1];
                    }
                }

                return response.blob();
            })
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename || 'download.csv';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                return blob;
            });
    } catch (error) {
        console.error('Fetch failed:', error);
        throw error;
    }
};

export const downloadCsv = async (reportname: string, params: any, date: Date) => {
    const id = params.id ? params.id : 'demo';
    const url = `${vake_api_server_url}/report/overwatch/${id}/detections/csv?date=${date.toISOString().split('T')[0]}`;
    const accessToken = localStorage.getItem('token')?.replaceAll('"', '');
    fetchDataAsFile(url, accessToken, reportname);
};

export const useValidateReportId = (params: any, reportTypeUrlString: string, validationNeeded: boolean) => {
    const id = params.id;
    const { token } = useAuth();
    const url = `${vake_api_server_url}/report/${reportTypeUrlString}/${id}`;
    const queryId = `validate_${reportTypeUrlString}_id`;

    return useQuery<Boolean>(
        [queryId, id],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                return false;
            }

            return true;
        },
        {
            enabled: validationNeeded,
        }
    );
};

export const useIsAdmin = (token: string | null | undefined) => {
    let url = `${vake_api_server_url}/report/admin`;

    return useQuery<boolean>(
        ['admin', null],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });
            if (res.status === 200) {
                return true;
            } else {
                return false;
            }
        },
        {
            enabled: !!token,
            staleTime: 1000 * 60 * 60, // one hour
            placeholderData: false,
        }
    );
};

export const useIsUser = (token: string | null | undefined) => {
    let url = `${vake_api_server_url}/report/user`;

    return useQuery<boolean>(
        ['user', null],
        async () => {
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });
            if (res.status === 200) {
                return true;
            } else {
                return false;
            }
        },
        {
            enabled: !!token,
            staleTime: 1000 * 60 * 60, // one hour
            placeholderData: false,
        }
    );
};

export const orderPathfinderSubscription = async (
    mmsi: string,
    fromTimestamp: Date,
    toTimestamp: Date | null,
    reportName: string,
    token: string | null | undefined,
    callback: Function
) => {
    const body = { mmsi: mmsi, start_time: fromTimestamp, end_time: toTimestamp, report_name: reportName };

    const res = await fetch(`${vake_api_server_url}/report/pathfinder/subscribe`, {
        method: 'POST',
        credentials: 'include',
        headers: getRequestHeaders(token),
        body: JSON.stringify(body),
    });

    if (!res.ok) {
        callback(false, undefined);
    }

    if (res.status === 200) {
        let output = await res.json();
        callback(true, output);
    }
};

export const orderOverwatchSubscription = async (
    geometry: string,
    description: string,
    fromTimestamp: Date,
    toTimestamp: Date | null,
    token: string | null | undefined,
    callback: Function
) => {
    let fromTimeString: string = '';
    let toTimeString: null | string = null;
    if (fromTimestamp) {
        // Ensure right string format
        fromTimeString = fromTimestamp.toJSON();
    }
    if (toTimestamp) {
        toTimeString = toTimestamp.toJSON();
    }
    const body = {
        geometry: geometry,
        start_time: fromTimeString,
        end_time: toTimeString,
        description: description,
    };

    const res = await fetch(`${vake_api_server_url}/report/overwatch/subscribe`, {
        method: 'POST',
        credentials: 'include',
        headers: getRequestHeaders(token),
        body: JSON.stringify(body),
    });

    if (!res.ok) {
        callback(SubscriptionState.failed, undefined);
    }

    if (res.status === 200) {
        let output = await res.json();
        callback(SubscriptionState.success, output);
    }
};

export const markAsInteresting = async (uuid: string, newMark: string | null, token: string | null | undefined) => {
    const body = {
        mark: newMark,
        report_uuid: uuid,
    };
    const res = await fetch(`${vake_api_server_url}/report/pathfinder/mark_pathfinder_report`, {
        method: 'POST',
        credentials: 'include',
        headers: getRequestHeaders(token),
        body: JSON.stringify(body),
    });

    if (!res.ok) {
        throw new Error(res.statusText);
    }
};

export const updatePFReportName = async (reportName: string, reportUUID: string, token: string | null | undefined) => {
    const body = {
        report_name: reportName,
        report_uuid: reportUUID,
    };
    const res = await fetch(`${vake_api_server_url}/report/pathfinder/change_report_name`, {
        method: 'POST',
        credentials: 'include',
        headers: getRequestHeaders(token),
        body: JSON.stringify(body),
    });

    if (!res.ok) {
        throw new Error(res.statusText);
    }
};

export const updatePFDescription = async (
    newReportDescription: string,
    reportUUID: string,
    token: string | null | undefined
) => {
    const body = {
        new_description: newReportDescription,
        report_uuid: reportUUID,
    };
    const res = await fetch(`${vake_api_server_url}/report/pathfinder/change_report_description`, {
        method: 'POST',
        credentials: 'include',
        headers: getRequestHeaders(token),
        body: JSON.stringify(body),
    });

    if (!res.ok) {
        throw new Error(res.statusText);
    }
};
