import { Slider } from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';
import constants from '../shared/constants';
import { useDebounce } from './debounce';

const PFSliderWrapper = styled.div<{ widthPercent: number }>`
    position: absolute;
    z-index: 100;
    width: ${(props) => props.widthPercent}%;
    left: ${(props) => 50 - props.widthPercent / 2}%;
    top: 90%;
`;

const ConfidenceSliderWrapper = styled.div`
    padding: 12px 20px 0px;
    font-size: 17px;
`;

const SliderContainer = styled.div`
    border-radius: 5px;
    padding: 5px 15px;
    background-color: ${constants.colors.pallet.lightBlack};
`;

const DateContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 15px;
    border-radius: 5px;
`;

const CustomSlider = styled(Slider)(() => ({
    color: constants.colors.pallet.chilledBlue, //color of the slider between thumbs
    '& .MuiSlider-thumb': {
        backgroundColor: constants.colors.pallet.blue, //color of thumbs
    },
    '& .MuiSlider-rail': {
        color: constants.colors.pallet.chilledBlue, //color of the slider outside  the area between thumbs
    },
}));

export const PathfinderSlider: FC<{
    startDate: number;
    endDate: number;
    minDate: number;
    maxDate: number;
    setFilterStartDate: (value: number) => void;
    setFilterEndDate: (value: number) => void;
}> = ({ startDate, endDate, minDate, maxDate, setFilterStartDate, setFilterEndDate }) => {
    const [timeSliderValues, setValue] = useState<number[]>([minDate, maxDate]);
    const handleSliderChangecommitted = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setFilterStartDate(newValue[0]);
            setFilterEndDate(newValue[1]);
        }
    };

    const debounceHandleSliderChange = useDebounce(
        (event, newValue) => handleSliderChangecommitted(event, newValue),
        100 // milliseconds
    );

    const handleSliderChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
        debounceHandleSliderChange(event, newValue);
    };

    return (
        <PFSliderWrapper widthPercent={40}>
            <SliderContainer>
                <DateContainer>
                    <div>From: {new Date(timeSliderValues[0]).toISOString().split('T')[0]}</div>
                    <div style={{ fontWeight: 500 }}>Select dates</div>
                    <div>To: {new Date(timeSliderValues[1]).toISOString().split('T')[0]}</div>
                </DateContainer>
                <CustomSlider value={timeSliderValues} min={minDate} max={maxDate} onChange={handleSliderChange} />
            </SliderContainer>
        </PFSliderWrapper>
    );
};

export const ConfidenceSlider: FC<{
    detectionConfidence: number;
    setDetectionConfidence: (confidence: number) => void;
}> = ({ detectionConfidence, setDetectionConfidence }) => {
    const handleSliderChange = (e: Event, newValue: number | number[]) => {
        if (!Array.isArray(newValue)) {
            setDetectionConfidence(newValue);
        }
    };
    return (
        <ConfidenceSliderWrapper>
            Dark detection model confidence
            <CustomSlider
                value={detectionConfidence}
                min={0}
                max={100}
                step={1}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => <div>{`${value}%`}</div>}
                onChange={handleSliderChange}
            />
        </ConfidenceSliderWrapper>
    );
};
